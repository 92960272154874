import React, { Fragment, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewProduct,
  addProceedBool,
  getSelectedProduct,
  getSelectedReason,
  getSelectedSubReason,
  mobSelected,
  returnQuantity,
  returnType,
} from "features/product/productSlice";
import { ReactComponent as CloseIcon } from "assets/close.svg";
import { useEffect } from "react";
import { getCollections, getDifferentVariants } from "utils/api";
import { getMerchantConfig } from "features/config/configSlice";
// import { ReactComponent as Frown } from "assets/frown.svg";
import { ReactComponent as Search } from "assets/search.svg";
import { StyledDifferentProduct, StyledVariantSelect } from "./styled";
import {
  addReturnedProducts,
  getReturnedProducts,
} from "features/returnedProducts/returnedProductSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactComponent as ErrorIcon } from "assets/error.svg";
import { updateWebSelected } from "features/orders/orderSlice";
import { toast } from "react-toastify";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

function DifferentProduct({
  quantityInc,
  productType,
  requiredProduct,
  setRequiredProduct,
  selectedVariant,
  setSelectedVariant,
  otherReason,
}) {
  const [query, setQuery] = useState(null);
  const [offset, setOffset] = useState(0);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [differentProductLoader, setDifferentProductLoader] = useState(true);
  const [differentProducts, setDifferentProducts] = useState([]);
  const [collections, setCollections] = useState([]);
  const [collectionLoader, setCollectionLoader] = useState(true);
  const [collectionId, setCollectionId] = useState(null);
  const [viewMoreLoader, setViewMoreLoader] = useState(false);
  const [differentApiError, setDifferentApiError] = useState(false);
  const [showViewMore, setShowViewMore] = useState(true);
  const [searchBy, setSearchBy] = useState("title");
  const [skuQuery, setSkuQuery] = useState(null);
  const [searchInput, setSearchInput] = useState(null);

  const selectedProduct = useSelector(getSelectedProduct);
  const merchantConfig = useSelector(getMerchantConfig);
  const returnedProducts = useSelector(getReturnedProducts);
  const reason = useSelector(getSelectedReason);
  const subReason = useSelector(getSelectedSubReason);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const encipherencode =
    sessionStorage.getItem("encipherencode") || params.get("encipherencode");

  const colors = ["#EAA717", "#DE4B92", "#2871F0", "#16AC37"];
  toast.configure();

  const callDifferentProducts = (collectionid, newOffset, viewMoreBool) => {
    getDifferentVariants(
      merchantConfig.merchant_id,
      selectedProduct.product_id,
      quantityInc,
      selectedProduct.product_price,
      newOffset,
      collectionid == false ? undefined : collectionid,
      query,
      skuQuery,
      selectedProduct.variant_id,
      selectedProduct.sku
    )
      .then((resp) => {
        if (resp.data.length === 0) {
          setShowViewMore(false);
        }
        if (collectionid !== null && !viewMoreBool) {
          setDifferentProducts(resp.data);
        } else {
          setDifferentProducts(differentProducts.concat(resp.data));
        }
        setDifferentProductLoader(false);
        setViewMoreLoader(false);
        setDifferentApiError(false);
      })
      .catch((err) => {
        if (differentProducts.length > 0) {
          toast.error("Could not load more products. Try again.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        } else {
          setDifferentApiError(true);
        }
      });
  };

  useEffect(() => {
    callDifferentProducts();
    getCollections(merchantConfig.merchant_id)
      .then((resp) => {
        setCollections(resp.data);
        setCollectionLoader(false);
      })
      .catch((err) => {
        setCollectionLoader(false);
      });
  }, []);

  useEffect(() => {
    if (searchInput == "") {
      setDifferentProductLoader(true);
      callDifferentProducts();
    }
  }, [searchInput]);

  useEffect(() => {
    if (searchBy == "sku") {
      setSkuQuery(searchInput);
      setQuery(null);
    } else {
      setQuery(searchInput);
      setSkuQuery(null);
    }
  }, [searchBy]);

  const handleSearch = (collectionid, newOffset) => {
    getDifferentVariants(
      merchantConfig.merchant_id,
      selectedProduct.product_id,
      quantityInc,
      selectedProduct.product_price,
      newOffset,
      collectionid,
      query,
      skuQuery
    )
      .then((resp) => {
        setDifferentProducts(resp.data);
        setDifferentProductLoader(false);
        setViewMoreLoader(false);
        setDifferentApiError(false);
      })
      .catch((err) => {
        setDifferentApiError(true);
      });
  };

  const handleCollectionSearch = (collection, i) => {
    setDifferentProductLoader(true);
    if (selectedCollection !== i) {
      callDifferentProducts(collection.id);
      setSelectedCollection(i);
      setCollectionId(collection.id);
    } else {
      callDifferentProducts();
      setSelectedCollection(null);
      setCollectionId(false);
    }
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      setDifferentProductLoader(true);
      e.preventDefault();
      handleSearch();
    }
  };

  const handleProductExchange = () => {
    dispatch(mobSelected(false));
    dispatch(updateWebSelected(false));
    dispatch(addNewProduct(requiredProduct));
    dispatch(returnQuantity(quantityInc));
    dispatch(returnType("exchange"));
    let tempProducts = [...returnedProducts];
    let line_item_ids = tempProducts.map((a) => a.line_item_id);
    if (line_item_ids.indexOf(selectedProduct.line_item_id) !== -1) {
      let index = line_item_ids.indexOf(selectedProduct.line_item_id);
      tempProducts.splice(index, 1);
    }
    dispatch(
      addReturnedProducts([
        ...tempProducts,
        {
          merchant_id: merchantConfig.merchant_id,
          product_title: selectedProduct.product_title,
          variant_name: selectedProduct.variant_name,
          product_price: selectedProduct.product_price,
          variant_image_url: selectedProduct.image_url,
          variant_sku: selectedProduct.sku,
          product_id: selectedProduct.product_id,
          variant_id: selectedProduct.variant_id,
          quantity: quantityInc,
          remaining_qty: selectedProduct.quantity - quantityInc,
          total_item_discount: selectedProduct.total_item_discount,
          discounted_price: selectedProduct.discounted_price,
          original_product_price: selectedProduct.original_product_price,
          exchange_product_title: requiredProduct.product_title,
          exchange_variant_name: selectedVariant.title,
          exchange_product_sku: selectedVariant.sku,
          exchange_product_price: selectedVariant.price,
          exchange_variant_image_url:
            selectedVariant.variant_img || selectedVariant.src,
          exchange_product_id: requiredProduct.store_product_id,
          exchange_variant_id: selectedVariant.variant_id,
          exchange_type: "different",
          return_type: "exchange",
          customer_reason_code: reason.reason_code,
          customer_reason_label: reason.reason_label,
          customer_reason_comment: otherReason,
          customer_sub_reason_code:
            subReason && subReason !== null ? subReason.reason_code : null,
          customer_sub_reason_label:
            subReason && subReason !== null ? subReason.reason_label : null,
          line_item_id: selectedProduct.line_item_id,
        },
      ])
    );
    let url = "/return-cart";
    if (encipherencode !== null && encipherencode !== undefined) {
      url += `?encipherencode=${encipherencode}`;
    }
    navigate(url);
  };

  useEffect(() => {
    if (requiredProduct !== null) {
      setSelectedVariant(
        requiredProduct.variants.filter(
          (a) => a.variant_id != selectedProduct.variant_id
        )[0]
      );
      // setSelectedVariant(requiredProduct.variants[0]);
    }
  }, [requiredProduct]);

  const MoreLoader = () => {
    return [...Array(4).keys()].map((a) => {
      return (
        <Grid
          justifyContent="center"
          className="different-skeleton"
          item
          key={a}
        >
          <div className="diif-image">
            <Skeleton style={{ width: "100%", height: "200px" }} />
          </div>
          <Typography>
            {" "}
            <Skeleton />{" "}
          </Typography>
          <Typography style={{ margin: "5px 0px" }}>
            {" "}
            <Skeleton />
          </Typography>
        </Grid>
      );
    });
  };

  const viewMore = () => {
    setViewMoreLoader(true);
    callDifferentProducts(collectionId, offset + 10, true);
    setOffset(offset + 10);
  };

  const handleVariant = (event) => {
    setSelectedVariant(event.target.value);
  };

  return (
    <div>
      {requiredProduct === null ? (
        <Fragment>
          {productType !== null && (
            <p
              style={{
                color: "#333333",
                fontSize: "14px",
                margin: "20px 20px 0px",
                fontWeight: "500",
              }}
            >
              Exchanging with{" "}
              {productType === "same" ? " variant of same " : " different "}{" "}
              product.{" "}
              <span
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => {
                  dispatch(addProceedBool(false));
                  dispatch(addNewProduct(null));
                }}
              >
                {" "}
                Change
              </span>
            </p>
          )}

          <div
            style={{
              background: "#fff",
              position: "sticky",
              maxWidth: "inherit",
              top: "80px",
              margin: "0px",
              padding: "16px",
              zIndex: "9",
            }}
          >
            <div
              style={{
                background: "#F5F5F5",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 20px",
                  width: "100%",
                }}
              >
                <input
                  type="text"
                  placeholder="Search for a different product"
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                    if (searchBy == "sku") {
                      setSkuQuery(e.target.value);
                      setQuery(null);
                    } else {
                      setQuery(e.target.value);
                      setSkuQuery(null);
                    }
                  }}
                  onKeyPress={(e) => handleEnter(e)}
                  style={{
                    border: "none",
                    background: "transparent",
                    width: "100%",
                    padding: "10px 0px",
                    marginLeft: "0px",
                    outline: "none",
                  }}
                />
                {searchInput !== null &&
                  searchInput.length > 0 &&
                  searchInput !== "" && (
                    <p
                      style={{ cursor: "pointer", margin: "0px 5px 0px 14px" }}
                      onClick={() => {
                        setSearchInput("");
                        setQuery("");
                        setSkuQuery("");
                      }}
                    >
                      {" "}
                      <CloseIcon
                        style={{
                          width: "24px",
                          height: "24px",
                          marginTop: "4px",
                          stroke: "#333",
                        }}
                      />{" "}
                    </p>
                  )}
              </div>
              <div
                style={{
                  background: merchantConfig.primary_theme_color,
                  padding: "10px 1rem",
                  boxShadow: "-5px 0px 5px -2px #888",
                }}
                onClick={() => {
                  setDifferentProductLoader(true);
                  handleSearch();
                }}
              >
                <SearchIcon
                  style={{
                    color: merchantConfig.secondary_theme_color,
                    cursor: "pointer",
                    marginTop: "2px",
                  }}
                />
              </div>
            </div>
            <FormControl
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <span>Search by:</span>
              <RadioGroup
                row
                aria-labelledby="search-by-buttons"
                name="search-by-buttons"
                style={{ marginLeft: "10px" }}
              >
                <FormControlLabel
                  id="1"
                  value="Title"
                  control={
                    <Radio
                      checked={searchBy == "title"}
                      onChange={() => setSearchBy("title")}
                    />
                  }
                  label="Title"
                />
                <FormControlLabel
                  id="2"
                  value="SKU"
                  control={
                    <Radio
                      checked={searchBy == "sku"}
                      onChange={() => setSearchBy("sku")}
                    />
                  }
                  label="SKU"
                />
              </RadioGroup>
            </FormControl>
          </div>

          {collectionLoader ? (
            <Skeleton
              variant="rounded"
              height={30}
              style={{ margin: "20px 16px 0px", borderRadius: "16px" }}
            />
          ) : (
            <div className="collections-section">
              {collections.map((collection, i) => {
                return (
                  <p
                    onClick={() => {
                      handleCollectionSearch(collection, i);
                    }}
                    key={collection.id}
                    style={{
                      padding: "6px 12px",
                      background: `${colors[i % 4]}33`,
                      width: "fit-content",
                      whiteSpace: "nowrap",
                      borderRadius: "20px",
                      marginRight: "10px",
                      fontSize: "12px",
                      cursor: "pointer",
                      border:
                        selectedCollection === i
                          ? `1px solid ${colors[i % 4]}`
                          : "none",
                    }}
                  >
                    {collection.title}
                  </p>
                );
              })}
            </div>
          )}

          {/* <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', flexWrap: 'wrap', padding: '10px 20px'}}>
            {differentProducts.map((product)=>{
              return(
                <div style={{margin: '15px 0px', cursor: 'pointer'}} onClick={()=>setRequiredProduct(product)}>
                  <img src={product.image}  alt="Preview" style={{width: '150px',height: '200px',margin: '10px 0px'}} />
                  <p style={{margin: '10px 0px', color: '#333',fontSize: '12px', width: '140px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>{product.name}</p>
                  <div style={{display: 'flex'}}>
                    <p style={{color: product.offerPrice !==null ? "#33333366" : "#333", textDecoration:  product.offerPrice !==null ? 'line-through': ''}}>₹ {product.price}</p>
                    {product.offerPrice !== null && <p style={{color: '#333', marginLeft: '10px'}}>₹ {product.offerPrice}</p> }
                  </div>
                </div>
              )
            })}
          </div>  */}

          <div>
            {differentProductLoader ? (
              <Grid
                container
                gridTemplateColumns="repeat(12, 1fr)"
                columnSpacing={{ xs: 3, md: 5 }}
                rowSpacing={{ xs: 0, md: 4 }}
                columns={12}
                style={{
                  padding: "0px 24px",
                  justifyContent: "center",
                  marginTop: "0px",
                }}
              >
                {" "}
                <MoreLoader />{" "}
              </Grid>
            ) : differentApiError ? (
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "center",
                  flexDirection: "column",
                  marginTop: "100px",
                }}
              >
                <ErrorIcon />
                <p
                  style={{
                    marginTop: "25px",
                    color: "#1d8dcc",
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontSize: "20px",
                  }}
                  onClick={() => window.location.reload()}
                >
                  Reload
                </p>
              </div>
            ) : differentProducts.length > 0 ? (
              <Grid
                container
                gridTemplateColumns="repeat(12, 1fr)"
                columnSpacing={{ xs: 3, md: 5 }}
                rowSpacing={{ xs: 0, md: 4 }}
                columns={12}
                style={{
                  padding: "0px 24px",
                  justifyContent: "center",
                  marginTop: "0px",
                }}
              >
                {differentProducts.map((product, i) => {
                  return (
                    <Grid
                      xs={6}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                      }}
                      item
                      onClick={() => setRequiredProduct(product)}
                      key={i}
                    >
                      <div style={{ width: "100%", margin: "10px 0px 5px" }}>
                        <LazyLoadImage
                          effect="opacity"
                          src={
                            product.variants[0].variant_img ||
                            product.variants[0].src
                          }
                          alt="Preview"
                          style={{ width: "100%", objectFit: "contain" }}
                        />
                      </div>
                      <p
                        style={{
                          margin: "5px 0px",
                          color: "#333",
                          fontSize: "14px",
                        }}
                      >
                        {product.product_title}
                      </p>
                      <p
                        style={{
                          margin: "5px 0px 10px",
                          color: "#333",
                          fontSize: "12px",
                          width: "140px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textTransform: "uppercase",
                        }}
                      >
                        {product.variants
                          .filter((a) => {
                            return !reason.same_variant_exchange
                              ? a.variant_id != selectedProduct.variant_id
                              : true;
                          })
                          .map((variant, i) => {
                            return (
                              <span
                                key={i}
                                style={{
                                  border: "1px solid #d5d5d5",
                                  padding: "2px 4px",
                                  marginRight: "5px",
                                }}
                              >
                                {variant.title.indexOf("Default") === -1
                                  ? variant.title
                                  : product.product_title}
                              </span>
                            );
                          })}
                      </p>
                      {/* <p style={{color: Number(selectedProduct.product_price) > Number(product.variants[0].price) ? '#3cee3c' : '#333', fontSize: '16px',margin: '5px 0px',fontWeight: '600'}}>₹ {product.variants[0].price}</p> */}
                      {product.out_of_stock &&
                        !product.quantity_available_for_exchange && (
                          <p
                            className="out-of-stock"
                            style={{
                              color: "red",
                              marginBottom: "8px",
                            }}
                          >
                            Out of stock
                          </p>
                        )}
                      <p
                        style={{
                          color: "#333",
                          fontSize: "16px",
                          margin: "auto 0px 5px",
                          fontWeight: "600",
                        }}
                      >
                        ₹ {product.variants[0].price}
                      </p>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Grid
                container
                gridTemplateColumns="repeat(12, 1fr)"
                columnSpacing={{ xs: 3, md: 5 }}
                rowSpacing={{ xs: 0, md: 4 }}
                columns={12}
                style={{
                  padding: "0px 24px",
                  justifyContent: "center",
                  marginTop: "0px",
                }}
              >
                <Grid
                  item
                  style={{
                    justifyContent: "center",
                    textAlign: "center",
                    marginTop: "4rem",
                  }}
                >
                  {/* <Frown /> */}
                  <Search />
                  <p style={{ textAlign: "center", marginTop: "1rem" }}>
                    {(query !== null && query.length > 0 && query !== "") ||
                    collectionId !== null
                      ? "Hmm we don't have that..yet"
                      : "Start typing to search new products"}
                  </p>
                </Grid>
              </Grid>
            )}
            {viewMoreLoader && (
              <Grid
                container
                gridTemplateColumns="repeat(12, 1fr)"
                columnSpacing={{ xs: 3, md: 5 }}
                rowSpacing={{ xs: 0, md: 4 }}
                columns={12}
                style={{
                  padding: "0px 24px",
                  justifyContent: "center",
                  marginTop: "0px",
                }}
              >
                <MoreLoader />
              </Grid>
            )}
          </div>

          {!differentApiError &&
            showViewMore &&
            differentProducts.length > 10 && (
              <p
                style={{
                  textAlign: "center",
                  color: "#333",
                  textDecoration: "underline",
                  margin: "20px 5px",
                  cursor: "pointer",
                }}
                onClick={() => viewMore()}
              >
                View More
              </p>
            )}
        </Fragment>
      ) : (
        <div style={{ margin: "10px 0px" }}>
          <StyledDifferentProduct>
            <LazyLoadImage
              effect="opacity"
              className="product-image"
              src={
                selectedVariant !== null
                  ? selectedVariant.variant_img || selectedVariant.src
                  : requiredProduct.variants[0].variant_img ||
                    selectedVariant.src
              }
              alt="Product-preview"
            />
            <div className="product-details">
              <p className="product-title">{requiredProduct.product_title}</p>
              {/* <div style={{display: 'flex', fontSize: '14px'}}>
                  <p style={{color: requiredProduct.offerPrice !==null ? "#33333366" : "#333", textDecoration:  requiredProduct.offerPrice !==null ? 'line-through': ''}}>₹ {requiredProduct.price}</p>
                  {requiredProduct.offerPrice !== null && <p style={{color: '#333', marginLeft: '10px'}}>₹ {requiredProduct.offerPrice}</p> }
              </div> */}
              <p
                style={{
                  color: "#333333B2",
                  fontSize: "12px",
                  margin: "10px 0px",
                }}
              >
                {(selectedVariant !== null
                  ? selectedVariant.title
                  : requiredProduct.variants[0].title
                ).indexOf("Default") === -1
                  ? selectedVariant !== null
                    ? selectedVariant.title
                    : requiredProduct.variants[0].title
                  : requiredProduct.product_title}
              </p>
              <p style={{ color: "#333", margin: "6px 0px" }}>
                ₹{" "}
                {selectedVariant !== null
                  ? selectedVariant.price
                  : requiredProduct.variants[0].price}{" "}
              </p>
              {selectedVariant &&
                selectedVariant !== null &&
                (selectedVariant.out_of_stock ||
                  !selectedVariant.quantity_available_for_exchange) && (
                  <p
                    className="out-of-stock"
                    style={{
                      color: "red",
                    }}
                  >
                    Out of stock
                  </p>
                )}
              {requiredProduct.variants.length >= 10 && (
                <TextField
                  style={{ width: "fit-content", margin: "15px 0px" }}
                  select
                  variant="outlined"
                  value={selectedVariant}
                  onChange={handleVariant}
                >
                  {requiredProduct.variants
                    .filter((a) => {
                      return !reason.same_variant_exchange
                        ? a.variant_id != selectedProduct.variant_id
                        : true;
                    })
                    .map((variant) => {
                      return (
                        <MenuItem value={variant} key={variant.id}>
                          {variant.title}
                        </MenuItem>
                      );
                    })}
                </TextField>
              )}
            </div>
          </StyledDifferentProduct>

          {/* {requiredProduct.variants.length > 10 ? <div style={{padding: '0px 20px'}}> 
            {/* <p style={{margin: '20px 0px 10px',fontSize: '14px', fontWeight: '500'}}>Select Variant</p>
            <TextField style={{width: 'fit-content'}} select variant="outlined" value={selectedVariant}
              onChange={handleVariant} label = "Select Variant">
            {/* <InputLabel id="variant-label">Variant</InputLabel>
            <Select
              labelId="variant-label"
              value={selectedVariant}
              onChange={handleVariant}
              autoWidth
              label={"Variant"}
              style={{marginTop: '10px'}}
              > 
                {requiredProduct.variants.map((variant)=>{
                      return(
                        <MenuItem value={variant} key={variant.id}>
                          {variant.title}
                        </MenuItem>
                      )
                  })
                } 
                </TextField>
            {/* </Select>
          </div> :  */}
          {requiredProduct.variants.length < 10 &&
            requiredProduct.variants.length > 1 && (
              <StyledVariantSelect>
                <p
                  style={{
                    margin: "10px 0px",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  Select Variant
                </p>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {requiredProduct.variants
                    .filter((a) => {
                      return !reason.same_variant_exchange
                        ? a.variant_id != selectedProduct.variant_id
                        : true;
                    })
                    .map((variant) => {
                      return (
                        <Button
                          disableRipple
                          variant={
                            selectedVariant !== null &&
                            selectedVariant.id === variant.id
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => setSelectedVariant(variant)}
                          style={{
                            fontSize: "12px",
                            minWidth: "50px",
                            width: "fit-content",
                            margin: "8px",
                            marginLeft: "0px",
                            background:
                              selectedVariant !== null &&
                              selectedVariant.id === variant.id
                                ? `${merchantConfig.primary_theme_color}1A`
                                : "transparent",
                            color:
                              selectedVariant !== null &&
                              selectedVariant.id === variant.id
                                ? `#000`
                                : `${merchantConfig.primary_theme_color}`,
                          }}
                          key={variant.id}
                        >
                          {variant.title}
                        </Button>
                      );
                    })}
                </div>
              </StyledVariantSelect>
            )}

          <div className="diff-prod-buttons" style={{ display: "block" }}>
            <Button
              className="exchange-button-web"
              color="primary"
              onClick={() => handleProductExchange()}
              variant="contained"
              disabled={
                selectedVariant &&
                selectedVariant !== null &&
                (selectedVariant.out_of_stock ||
                  !selectedVariant.quantity_available_for_exchange)
              }
            >
              Select for exchange
            </Button>
            <Button
              variant="text"
              color="primary"
              className="back-button"
              onClick={() => setRequiredProduct(null)}
            >
              Back
            </Button>
            <div className="exchange-button-mob">
              <Button
                color="primary"
                fullWidth
                onClick={() => handleProductExchange()}
                variant="contained"
                style={{
                  transform: "translateX(-50%)",
                  left: "50%",
                  padding: "14px 0px",
                }}
                disabled={
                  selectedVariant &&
                  selectedVariant !== null &&
                  (selectedVariant.out_of_stock ||
                    !selectedVariant.quantity_available_for_exchange)
                }
              >
                Select for exchange
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DifferentProduct;
