import React from "react";
import { StyledFooterWrapper } from "./styled";

function Footer() {
  return (
    <StyledFooterWrapper>
      Powered by
      <div>
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 2880 360"
        >
          <path
            className="cls-1"
            fill="#221f1f"
            d="M8.75,331.53V22.6H165.42c63.11,0,101.5,41.93,101.5,97.09,0,62.23-43.25,100.19-101.5,100.19H61.71V331.53Zm53-159.32H162.77c33.1,0,50.31-24.72,50.31-52.52,0-29.13-19.86-49.43-50.31-49.43H61.71Z"
          />
          <path
            className="cls-1"
            fill="#221f1f"
            d="M446.94,331.53V22.6H597.87c58.7,0,99.3,35.75,99.3,87.38,0,38-26.92,69.29-68,79.88,20.3,4,34.86,16.33,42.36,35.31l39.72,106.36H654.8L619.05,234c-4.41-12.35-14.56-19-28.68-19H499.9V331.53Zm53-163.29h95.33c32.65,0,49.42-23.39,49.42-50.31,0-28.25-19.42-47.67-49.42-47.67H499.9Z"
          />
          <path
            className="cls-1"
            fill="#221f1f"
            d="M861.74,331.53,988,22.6h53l126.66,308.93h-54.73l-31.33-76.35H947.8l-31.33,76.35Zm105-123.13h95.77L1014.88,91Z"
          />
          <path
            className="cls-1"
            fill="#221f1f"
            d="M1512.52,75.32c-13.88-7.6-30.23-11.67-48.41-11.67-61.79,0-100.63,43.25-100.63,112.53,0,70.17,39.28,113.86,98.42,113.86,48.11,0,79.44-31.33,79.44-75.47h-93.12V170H1553.7c39.71,0,61.34,19.86,61.34,57.37V331.53h-46.78V237.08h-.88c-6.18,61.79-46.78,101.07-110.77,101.07-86.51,0-147.41-63.55-147.41-162,0-93.56,59.14-160.2,154.91-160.2,21.68,0,41.53,3.47,59.21,10.08,6.42,2.4,12.25,4.89,15.44,6.84"
          />
          <path
            className="cls-1"
            fill="#221f1f"
            d="M1803.45,331.53V22.6h65.31L1965.41,268,2061.62,22.6h65.31V331.53h-53V114l-85.62,217.58H1942L1856.4,114.39V331.53Z"
          />
          <path
            className="cls-1"
            fill="#221f1f"
            d="M2299.44,331.53,2425.66,22.6h53l126.66,308.93h-54.72l-31.34-76.35H2385.5l-31.33,76.35Zm105-123.13h95.77L2452.58,91Z"
          />
          <circle
            className="cls-2"
            cx="2817.02"
            cy="289.79"
            r="54.23"
            fill="#2871f0"
          />
        </svg>
      </div>
    </StyledFooterWrapper>
  );
}

export default Footer;
