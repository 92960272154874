import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Skeleton,
  TextareaAutosize,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { StyledReasonWrapper } from "./styled";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import Quantity from "./quantity";
import ImageUpload from "./ImageUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SameProduct from "./SameProduct";
import DifferentProduct from "./DifferentProduct";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getReasons, validateMedia } from "utils/api";
import { useDispatch, useSelector } from "react-redux";
import { getMerchantConfig, getRmsConfig } from "features/config/configSlice";
import {
  addProceedBool,
  addProductType,
  addSelectedReason,
  addSelectedSubReason,
  addUploadMediaIds,
  getPreviews,
  getProceedBool,
  getProductid,
  getProductType,
  getSelectedProduct,
  getSelectedReason,
  getSelectedSubReason,
  getUploads,
  mobSelected,
  setImageUploads,
} from "features/product/productSlice";
import {
  addReturnedProducts,
  getReturnedProducts,
} from "features/returnedProducts/returnedProductSlice";
import { ReactComponent as CloseIcon } from "assets/close.svg";
import { BeatLoader } from "react-spinners";
import {
  getSelectedOrder,
  updateWebSelected,
} from "features/orders/orderSlice";
import { ReactComponent as ErrorIcon } from "assets/error.svg";
import { toast } from "react-toastify";

function Reason({ handleOnSelect }) {
  const [selectedReason, setSelectedReason] = useState(
    useSelector(getSelectedReason)
  );
  const [selectedSubReason, setSelectedSubReason] = useState(
    useSelector(getSelectedSubReason)
  );
  const productType = useSelector(getProductType);
  const [uploads, setUploads] = useState(useSelector(getUploads));
  const selectedProduct = useSelector(getSelectedProduct);
  const [quantityInc, setQuantityInc] = useState(selectedProduct.remaining_qty);
  const [preview, setPreview] = useState(useSelector(getPreviews));
  const proceed = useSelector(getProceedBool);
  const merchantConfig = useSelector(getMerchantConfig);
  const [reasons, setReasons] = useState([]);
  const [uploadError, setUploadError] = useState(null);
  const rmsConfig = useSelector(getRmsConfig);
  const productid = useSelector(getProductid);
  const returnedProducts = useSelector(getReturnedProducts);
  const [reasonLoading, setReasonLoading] = useState(true);
  const [requiredProduct, setRequiredProduct] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [verificationLoader, setVerificationLoader] = useState(false);
  const [reasonApiError, setReasonApiError] = useState(false);
  const [exchangeRequired, setExchangeRequired] = useState(false);
  const [otherReason, setOtherReason] = useState(null);
  const [otherReasonError, setOtherReasonError] = useState(false);
  const selectedOrder = useSelector(getSelectedOrder);
  const [uploadMediaIds, setUploadMediaIds] = useState([]);

  const [params, setParams] = useSearchParams();
  const encipherencode =
    sessionStorage.getItem("encipherencode") || params.get("encipherencode");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  toast.configure();

  useEffect(() => {
    getReasons(
      merchantConfig.merchant_id,
      productid !== null && productid.indexOf("return") !== -1
        ? "refund"
        : "exchange"
    )
      .then((resp) => {
        setReasons(resp.data);
        setReasonLoading(false);
      })
      .catch((err) => {
        setReasonApiError(true);
      });
  }, []);

  const handleReason = (reason, openBox, setOpenbox) => {
    reason.sub_reasons.length > 0
      ? setOpenbox(!openBox)
      : setSelectedReason(reason);
    dispatch(addSelectedReason(reason));
  };

  const handleSubReason = (reason, subreason) => {
    setSelectedReason(reason);
    setSelectedSubReason(subreason);
    dispatch(addSelectedReason(reason));
    dispatch(addSelectedSubReason(subreason));
  };

  const Reasonbox = ({ reason }) => {
    const [openBox, setOpenbox] = useState(false);
    const Icon = () => {
      return openBox ? (
        <KeyboardArrowUpIcon style={{ color: "#333333D9" }} />
      ) : (
        <KeyboardArrowDownIcon style={{ color: "#333333D9" }} />
      );
    };

    return (
      <AnimateSharedLayout>
        <motion.div
          layout
          style={{
            border: openBox ? "1px solid #1d8dcc" : "1px solid #3333331A",
            borderRadius: "6px",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px",
              cursor: "pointer",
            }}
            onClick={() => {
              handleReason(reason, openBox, setOpenbox);
            }}
          >
            <motion.p layout style={{ color: "#333333D9" }}>
              {reason.reason_label}
            </motion.p>
            {reason.sub_reasons.length > 0 && (
              <motion.div layout>
                {" "}
                <Icon />{" "}
              </motion.div>
            )}
          </div>
          <AnimatePresence>
            {openBox && reason.sub_reasons.length > 0 && (
              <div>
                {reason.sub_reasons.map((subreason, i) => {
                  return (
                    <motion.div
                      key={i}
                      layout
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "10px",
                        border:
                          selectedReason !== null &&
                          selectedReason.reason_label === subreason.reason_label
                            ? "1px solid #1d8dcc"
                            : "1px solid #3333331A",
                        borderRadius: "6px",
                        margin: "15px 10px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleSubReason(reason, subreason);
                      }}
                    >
                      <p>{subreason.reason_label}</p>
                    </motion.div>
                  );
                })}
              </div>
            )}
          </AnimatePresence>
        </motion.div>
      </AnimateSharedLayout>
    );
  };

  const dispatchReturnedProducts = () => {
    let tempProducts = [...returnedProducts];
    let line_item_ids = tempProducts.map((a) => a.line_item_id);
    if (line_item_ids.indexOf(selectedProduct.line_item_id) !== -1) {
      let index = line_item_ids.indexOf(selectedProduct.line_item_id);
      tempProducts.splice(index, 1);
    }
    dispatch(
      addReturnedProducts([
        ...tempProducts,
        {
          merchant_id: merchantConfig.merchant_id,
          product_title: selectedProduct.product_title,
          variant_name: selectedProduct.variant_name,
          product_price: selectedProduct.product_price,
          variant_image_url: selectedProduct.image_url,
          variant_sku: selectedProduct.sku,
          product_id: selectedProduct.product_id,
          variant_id: selectedProduct.variant_id,
          total_item_discount: selectedProduct.total_item_discount,
          discounted_price: selectedProduct.discounted_price,
          original_product_price: selectedProduct.original_product_price,
          quantity: quantityInc,
          remaining_qty: selectedProduct.quantity - quantityInc,
          return_type: "refund",
          customer_reason_code: selectedReason.reason_code,
          customer_reason_label: selectedReason.reason_label,
          customer_reason_comment: otherReason,
          customer_sub_reason_code:
            selectedSubReason !== undefined && selectedSubReason !== null
              ? selectedSubReason.reason_code
              : null,
          customer_sub_reason_label:
            selectedSubReason !== undefined && selectedSubReason !== null
              ? selectedSubReason.reason_label
              : null,
          line_item_id: selectedProduct.line_item_id,
        },
      ])
    );
    dispatch(addProceedBool(true));
    dispatch(updateWebSelected(false));
    dispatch(mobSelected(false));
    setVerificationLoader(false);
    setExchangeRequired(false);
    let url = "/return-cart";
    if (encipherencode !== null && encipherencode !== undefined) {
      url += `?encipherencode=${encipherencode}`;
    }
    navigate(url);
  };

  const handleProceed = () => {
    if (productType !== null) {
      if (
        selectedReason.reason_code == "other" ||
        selectedReason.reason_code.toLowerCase().includes("other")
      ) {
        if (otherReason !== null && otherReason !== "") {
          setOtherReasonError(false);
          if (selectedReason.is_media_required) {
            let formData = new FormData();
            for (let i = 0; i < uploads.length; i++) {
              formData.append("files", uploads[i]);
            }
            validateMedia(
              formData,
              merchantConfig.merchant_id,
              true,
              selectedReason.id,
              selectedReason.reason_code,
              selectedReason.return_type,
              selectedOrder.order_id,
              selectedOrder.order_number
            )
              .then((resp) => {
                var tempUploadIds = [
                  ...uploadMediaIds,
                  ...resp.data.uploaded_media_ids,
                ];
                dispatch(addUploadMediaIds(tempUploadIds));
                dispatch(addProceedBool(true));
                dispatch(setImageUploads(formData));
              })
              .catch((err) => {
                setUploadError(err.response.data.error);
              })
              .finally(() => {
                setExchangeRequired(false);
                setVerificationLoader(false);
              });
          } else {
            dispatch(addProceedBool(true));
            dispatch(setImageUploads([]));
            setVerificationLoader(false);
            setExchangeRequired(false);
          }
        } else {
          setVerificationLoader(false);
          toast.error("Please mention the reason in the comments", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          setOtherReasonError(true);
        }
      } else {
        setOtherReasonError(false);
        if (selectedReason.is_media_required) {
          let formData = new FormData();
          for (let i = 0; i < uploads.length; i++) {
            formData.append("files", uploads[i]);
          }
          validateMedia(
            formData,
            merchantConfig.merchant_id,
            true,
            selectedReason.id,
            selectedReason.reason_code,
            selectedReason.return_type,
            selectedOrder.order_id,
            selectedOrder.order_number
          )
            .then((resp) => {
              var tempUploadIds = [
                ...uploadMediaIds,
                ...resp.data.uploaded_media_ids,
              ];
              dispatch(addUploadMediaIds(tempUploadIds));
              dispatch(addProceedBool(true));
              dispatch(setImageUploads(formData));
            })
            .catch((err) => {
              setUploadError(err.response.data.error);
            })
            .finally(() => {
              setExchangeRequired(false);
              setVerificationLoader(false);
            });
        } else {
          dispatch(addProceedBool(true));
          dispatch(setImageUploads([]));
          setVerificationLoader(false);
          setExchangeRequired(false);
        }
      }
    } else {
      toast.error("Please select exchange preference", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      setVerificationLoader(false);
      setExchangeRequired(true);
    }
  };

  const handleRefundProceed = () => {
    if (
      selectedReason.reason_code == "other" ||
      selectedReason.reason_code.toLowerCase().includes("other")
    ) {
      if (otherReason !== null && otherReason !== "") {
        setVerificationLoader(false);
        if (selectedReason.is_media_required) {
          let formData = new FormData();
          for (let i = 0; i < uploads.length; i++) {
            formData.append("files", uploads[i]);
          }
          validateMedia(
            formData,
            merchantConfig.merchant_id,
            true,
            selectedReason.id,
            selectedReason.reason_code,
            selectedReason.return_type,
            selectedOrder.order_id,
            selectedOrder.order_number
          )
            .then((resp) => {
              var tempUploadIds = [
                ...uploadMediaIds,
                ...resp.data.uploaded_media_ids,
              ];
              dispatch(addUploadMediaIds(tempUploadIds));
              dispatch(setImageUploads(formData));
              dispatchReturnedProducts();
            })
            .catch((err) => {
              setUploadError(err.response.data.error);
            })
            .finally(() => {
              setVerificationLoader(false);
              setExchangeRequired(false);
            });
        } else {
          dispatchReturnedProducts();
        }
      } else {
        toast.error("Please mention the reason in the comments", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        setTimeout(() => {
          setVerificationLoader(false);
        }, 100);
        setOtherReasonError(true);
      }
    } else {
      if (selectedReason.is_media_required) {
        let formData = new FormData();
        for (let i = 0; i < uploads.length; i++) {
          formData.append("files", uploads[i]);
        }
        validateMedia(
          formData,
          merchantConfig.merchant_id,
          true,
          selectedReason.id,
          selectedReason.reason_code,
          selectedReason.return_type,
          selectedOrder.order_id,
          selectedOrder.order_number
        )
          .then((resp) => {
            var tempUploadIds = [
              ...uploadMediaIds,
              ...resp.data.uploaded_media_ids,
            ];
            dispatch(addUploadMediaIds(tempUploadIds));
            dispatch(setImageUploads(formData));
            dispatchReturnedProducts();
          })
          .catch((err) => {
            setUploadError(err.response.data.error);
          })
          .finally(() => {
            setVerificationLoader(false);
            setExchangeRequired(false);
          });
      } else {
        dispatchReturnedProducts();
      }
    }
  };

  return (
    <StyledReasonWrapper
      primary_color={merchantConfig.primary_theme_color}
      selectedReason={selectedReason !== null && selectedReason.reason_label}
    >
      <div
        style={{
          padding: "20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
          position: "sticky",
          top: "0",
          background: "#fff",
          zIndex: "99",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {requiredProduct === null && productType !== null && proceed && (
            <ArrowBackIcon
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={() => {
                dispatch(addProceedBool(null));
                dispatch(addProductType(null));
              }}
            />
          )}
          {requiredProduct !== null && (
            <ArrowBackIcon
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={() => {
                setRequiredProduct(null);
                setSelectedVariant(null);
              }}
            />
          )}
          <img
            src={selectedProduct.image_url}
            alt="Preview"
            style={{ height: "40px", width: "40px", borderRadius: "4px" }}
          />
          <div style={{ marginLeft: "16px" }}>
            <p
              className="product_title"
              style={{
                width: "220px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {selectedProduct.product_title}
            </p>
          </div>
        </div>

        <p
          style={{ cursor: "pointer" }}
          onClick={() => {
            dispatch(updateWebSelected(false));
            dispatch(mobSelected(false));
          }}
        >
          {" "}
          <CloseIcon
            style={{ width: "24px", height: "24px", stroke: "#333" }}
          />{" "}
        </p>
      </div>

      <Divider />

      {proceed && productType === "same" ? (
        <SameProduct
          quantityInc={quantityInc}
          productType={productType}
          selectedVariant={selectedVariant}
          setSelectedVariant={setSelectedVariant}
          otherReason={otherReason}
        />
      ) : proceed && productType === "different" ? (
        <DifferentProduct
          quantityInc={quantityInc}
          productType={productType}
          requiredProduct={requiredProduct}
          setRequiredProduct={setRequiredProduct}
          selectedVariant={selectedVariant}
          setSelectedVariant={setSelectedVariant}
          otherReason={otherReason}
        />
      ) : (
        <Fragment>
          <div className="reason-form">
            {rmsConfig.restrict_multi_type_request_creation &&
            returnedProducts.length > 0 ? (
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  {rmsConfig.supports_exchange &&
                    returnedProducts.some(
                      (a) => a.return_type == "exchange"
                    ) && (
                      <FormControlLabel
                        id="2"
                        style={{ fontWeight: "600" }}
                        value="Exchange"
                        control={
                          <Radio
                            sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                            checked={
                              productid !== null &&
                              productid.indexOf("exchange") !== -1
                            }
                            onChange={() => {
                              handleOnSelect(
                                productid.replace(/exchange|return/g, ""),
                                "exchange",
                                selectedProduct
                              );
                            }}
                          />
                        }
                        disabled={
                          !selectedProduct.exchange_eligible ||
                          !rmsConfig.supports_exchange
                        }
                        label={<p className="radio-label">Exchange</p>}
                      />
                    )}
                  {rmsConfig.supports_refund &&
                    returnedProducts.some((a) => a.return_type == "refund") && (
                      <FormControlLabel
                        id="1"
                        style={{ fontWeight: "600" }}
                        value="Return"
                        disabled={
                          !selectedProduct.refund_eligible ||
                          !rmsConfig.supports_refund
                        }
                        control={
                          <Radio
                            sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                            style={{ fontSize: "24px" }}
                            checked={
                              productid !== null &&
                              productid.indexOf("return") !== -1
                            }
                            onChange={() => {
                              handleOnSelect(
                                productid.replace(/exchange|return/g, ""),
                                "return",
                                selectedProduct
                              );
                            }}
                          />
                        }
                        label={<p className="radio-label">Return</p>}
                      />
                    )}
                </RadioGroup>
              </FormControl>
            ) : (
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  {rmsConfig.supports_exchange && (
                    <FormControlLabel
                      id="2"
                      style={{ fontWeight: "600" }}
                      value="Exchange"
                      control={
                        <Radio
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                          checked={
                            productid !== null &&
                            productid.indexOf("exchange") !== -1
                          }
                          onChange={() => {
                            handleOnSelect(
                              productid.replace(/exchange|return/g, ""),
                              "exchange",
                              selectedProduct
                            );
                          }}
                        />
                      }
                      disabled={
                        !selectedProduct.exchange_eligible ||
                        !rmsConfig.supports_exchange
                      }
                      label={<p className="radio-label">Exchange</p>}
                    />
                  )}
                  {rmsConfig.supports_refund && (
                    <FormControlLabel
                      id="1"
                      style={{ fontWeight: "600" }}
                      value="Return"
                      disabled={
                        !selectedProduct.refund_eligible ||
                        !rmsConfig.supports_refund
                      }
                      control={
                        <Radio
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                          style={{ fontSize: "24px" }}
                          checked={
                            productid !== null &&
                            productid.indexOf("return") !== -1
                          }
                          onChange={() => {
                            handleOnSelect(
                              productid.replace(/exchange|return/g, ""),
                              "return",
                              selectedProduct
                            );
                          }}
                        />
                      }
                      label={<p className="radio-label">Return</p>}
                    />
                  )}
                </RadioGroup>
              </FormControl>
            )}

            <p
              style={{
                fontSize: "14px",
                fontWeight: "600",
                color: "#333",
                margin: "20px 0px 0px",
              }}
            >
              {" "}
              What is the reason for{" "}
              {productid.indexOf("exchange") !== -1 ? "exchange" : "return"} ?
            </p>

            {reasonApiError ? (
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "center",
                  flexDirection: "column",
                  marginTop: "100px",
                }}
              >
                <ErrorIcon />
                <p
                  style={{
                    marginTop: "25px",
                    color: "#1d8dcc",
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontSize: "20px",
                  }}
                  onClick={() => window.location.reload()}
                >
                  Reload
                </p>
              </div>
            ) : reasonLoading && selectedReason == null ? (
              <div>
                {[...Array(6).keys()].map((a) => {
                  return (
                    <div
                      style={{
                        border: "1px solid  rgba(17, 17, 17, 0.2)",
                        padding: "16px",
                        marginTop: "20px",
                        borderRadius: "6px",
                      }}
                      key={a}
                    >
                      <Skeleton type="text" style={{ width: "100%" }} />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="reasons">
                <AnimateSharedLayout>
                  <motion.div layout>
                    {reasons.length > 0 &&
                      reasons.map((reason, i) => {
                        return <Reasonbox reason={reason} key={i} />;
                      })}
                  </motion.div>
                </AnimateSharedLayout>
              </div>
            )}
          </div>

          {selectedReason && selectedReason !== null && (
            <div style={{ padding: "14px 24px" }}>
              <div
                style={{
                  padding: "16px",
                  border: "1px solid #3333331A",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <p style={{ color: "#333333D9", fontSize: "14px" }}>
                    {selectedReason.reason_label}
                  </p>
                  {selectedSubReason && selectedSubReason !== null && (
                    <p
                      style={{
                        marginTop: "20px",
                        color: "#333333B2",
                        fontSize: "14px",
                      }}
                    >
                      {selectedSubReason.reason_label}
                    </p>
                  )}
                </div>
                <p
                  style={{ color: "#333", fontSize: "16px", cursor: "pointer" }}
                  onClick={() => {
                    setSelectedSubReason(null);
                    setSelectedReason(null);
                  }}
                >
                  {" "}
                  <CloseIcon
                    style={{ width: "18px", height: "18px", stroke: "#333" }}
                  />{" "}
                </p>
              </div>

              {(selectedReason.reason_code == "other" ||
                selectedReason.reason_code.toLowerCase().includes("other")) && (
                <div>
                  <p
                    style={{
                      color: "#333",
                      fontSize: "14px",
                      marginTop: "10px",
                      fontWeight: "500",
                    }}
                  >
                    Comments
                  </p>
                  <TextareaAutosize
                    placeholder="Mention your reason here"
                    value={otherReason}
                    onChange={(e) => {
                      setOtherReason(e.target.value);
                      setOtherReasonError(false);
                    }}
                    style={{
                      width: "100%",
                      marginTop: "10px",
                      height: "70px",
                      padding: "10px",
                      fontFamily: "inherit",
                      fontSize: "14px",
                      border: otherReasonError
                        ? "1px solid #e74c3c"
                        : "1px solid #3333",
                    }}
                  />
                </div>
              )}

              {selectedReason.comment !== null &&
                selectedReason.comment !== "" && (
                  <p
                    style={{
                      color: "#333",
                      fontSize: "12px",
                      marginTop: "10px",
                    }}
                  >
                    <span
                      style={{
                        background: "#DD435D33",
                        color: "#DD435D99",
                        marginRight: "5px",
                        padding: "4px",
                        fontWeight: "500",
                        borderRadius: "2px",
                      }}
                    >
                      Note :
                    </span>
                    {selectedReason.comment}
                  </p>
                )}

              <Quantity
                quantityInc={quantityInc}
                setQuantityInc={setQuantityInc}
                originalQuantity={selectedProduct.remaining_qty}
              />

              {selectedReason.is_media_required && (
                <ImageUpload
                  selectedReason={selectedReason}
                  uploads={uploads}
                  setUploads={setUploads}
                  preview={preview}
                  setPreview={setPreview}
                />
              )}

              {uploadError !== null && (
                <p style={{ color: "red" }}>{uploadError}</p>
              )}

              {((uploads && uploads.length > 0) ||
                !selectedReason.is_media_required) && (
                <div>
                  {productid !== null &&
                  productid.indexOf("exchange") !== -1 ? (
                    <Fragment>
                      <p
                        className="preference"
                        style={{
                          color: !exchangeRequired ? "#333333" : "#ff0000",
                          fontWeight: "600",
                          fontSize: "14px",
                        }}
                      >
                        Select Exchange Preference
                      </p>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="product-type-options"
                          name="product-type-options"
                        >
                          <FormControlLabel
                            id="same"
                            checked={productType === "same"}
                            value="same"
                            control={
                              <Radio
                                style={{
                                  color: exchangeRequired
                                    ? "#ff000099"
                                    : "#333",
                                }}
                                size="small"
                                onChange={() => {
                                  dispatch(addProductType("same"));
                                  setExchangeRequired(false);
                                }}
                              />
                            }
                            label={
                              <p className="radio-label">
                                Variant of same product
                              </p>
                            }
                          />
                          {rmsConfig.exchange_with_any_product && (
                            <FormControlLabel
                              disabled={!rmsConfig.exchange_with_any_product}
                              id="different"
                              checked={productType === "different"}
                              value="different"
                              control={
                                <Radio
                                  size="small"
                                  style={{
                                    color: exchangeRequired
                                      ? "#ff000099"
                                      : "#333",
                                  }}
                                  onChange={() => {
                                    dispatch(addProductType("different"));
                                    setExchangeRequired(false);
                                  }}
                                />
                              }
                              label={
                                <p className="radio-label">Other product</p>
                              }
                            />
                          )}
                        </RadioGroup>
                      </FormControl>
                      <Button
                        variant="contained"
                        className="proceed"
                        style={{ display: "flex", margin: "24px auto 0px" }}
                        onClick={() => {
                          setVerificationLoader(true);
                          handleProceed();
                        }}
                        endIcon={verificationLoader ? "" : <ArrowForwardIcon />}
                      >
                        {" "}
                        {verificationLoader ? (
                          <BeatLoader
                            color={merchantConfig.secondary_theme_color}
                          />
                        ) : (
                          "Proceed"
                        )}{" "}
                      </Button>
                    </Fragment>
                  ) : (
                    <Button
                      variant="contained"
                      className="proceed"
                      style={{ display: "flex", margin: "24px auto 0px" }}
                      onClick={() => {
                        handleRefundProceed();
                        setVerificationLoader(true);
                      }}
                      endIcon={verificationLoader ? "" : <ArrowForwardIcon />}
                    >
                      {" "}
                      {verificationLoader ? (
                        <BeatLoader
                          color={merchantConfig.secondary_theme_color}
                        />
                      ) : (
                        "Proceed"
                      )}{" "}
                    </Button>
                  )}
                </div>
              )}
            </div>
          )}
        </Fragment>
      )}
    </StyledReasonWrapper>
  );
}

export default Reason;
