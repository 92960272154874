import React, { Fragment, useEffect, useState } from "react";
import Header from "../common/Header";
import {
  Button,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Skeleton,
  Typography,
} from "@mui/material";
import {
  StyledMobButtons,
  StyledProducts,
  StyledProductsWrapper,
} from "./styled";
import ReplayIcon from "@mui/icons-material/Replay";
import RepeatIcon from "@mui/icons-material/Repeat";
import Reason from "./reason";
import { motion } from "framer-motion";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { getMerchantConfig, getRmsConfig } from "features/config/configSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedOrder,
  getWebSelected,
  updateSelectedOrder,
  updateUUID,
  updateWebSelected,
} from "features/orders/orderSlice";
import { selectedOrderAPI } from "utils/api";
import {
  addSelectedProduct,
  addProductid,
  getProductid,
  getSelectedMob,
  mobSelected,
  addSelectedReason,
  addSelectedSubReason,
  setImageUploads,
  addPreviews,
  addUploads,
  addProductType,
  addProceedBool,
} from "features/product/productSlice";
import {
  addRefundJSON,
  addRefundSource,
  getReturnedProducts,
} from "features/returnedProducts/returnedProductSlice";
import { getEmail, getPhone } from "features/user/userSlice";
import { ReactComponent as ErrorIcon } from "assets/error.svg";

function ProductsDisplay() {
  const [productid, setProductid] = useState(useSelector(getProductid));
  const merchantConfig = useSelector(getMerchantConfig);
  const defaultSelectedOrder = useSelector(getSelectedOrder);
  const [selectedOrder, setSelectedOrder] = useState(defaultSelectedOrder);
  const dispatch = useDispatch();
  const selected = useSelector(getSelectedMob);
  const returnedProducts = useSelector(getReturnedProducts);
  const [productsLoading, setProductsLoading] = useState(true);
  const location = useLocation();
  const rmsConfig = useSelector(getRmsConfig);
  const webSelected = useSelector(getWebSelected);
  const userPhone = useSelector(getPhone);
  const userEmail = useSelector(getEmail);
  const navigate = useNavigate();
  const [apiErrorLoading, setApiErrorLoading] = useState(false);
  const [params, setParams] = useSearchParams();
  const encipherencode =
    sessionStorage.getItem("encipherencode") || params.get("encipherencode");
  const condition = true;

  useEffect(() => {
    if (location.pathname !== "/return-cart") {
      selectedOrderAPI(
        merchantConfig.merchant_id,
        selectedOrder.order_id,
        selectedOrder
      )
        .then((resp) => {
          dispatch(updateSelectedOrder(resp.data.order));
          dispatch(updateUUID(resp.data.unique_id));
          if (location.pathname === "/products") {
            setSelectedOrder(resp.data.order);
          }
          setProductsLoading(false);
        })
        .catch((err) => {
          if (userPhone === "" && userEmail === "") {
            let url = "/";
            if (encipherencode !== null && encipherencode !== undefined) {
              url += `?encipherencode=${encipherencode}`;
            }
            navigate(url);
          } else {
            setApiErrorLoading(true);
          }
        });
      dispatch(updateWebSelected(false));
      dispatch(mobSelected(false));
      dispatch(addProductid(null));
      setProductid(null);
      dispatch(addRefundSource(null));
      dispatch(addRefundJSON({}));
    } else {
      setProductsLoading(false);
    }
  }, []);

  function handleOnSelect(id, return_type, product) {
    setProductid(id + return_type);
    dispatch(addProductid(id + return_type));
    dispatch(addSelectedProduct(product));
    dispatch(addSelectedReason(null));
    dispatch(addSelectedSubReason(null));
    dispatch(setImageUploads([]));
    dispatch(addPreviews([]));
    dispatch(addUploads([]));
    dispatch(addProductType(null));
    dispatch(addProceedBool(false));
  }

  const ProductCard = ({ product, webSelected }) => {
    const currentProduct = selectedOrder.order_items.filter(
      (a) => a.product_title === product.product_title
    );

    return (
      <div
        style={{
          background: "#fff",
          border:
            product.line_item_id !== undefined &&
            productid !== null &&
            productid.includes(product.line_item_id)
              ? `1px solid ${merchantConfig.primary_theme_color}`
              : "1px solid #11111133",
          position: "relative",
          width:
            location.pathname === "/products" && webSelected ? "50%" : "100%",
          borderRadius: "4px",
        }}
      >
        <StyledProducts>
          {(currentProduct[0].quantity - product.quantity > 0 ||
            product.returned_qty > 0) &&
            product.remaining_qty > 0 && (
              <p
                className="top-banner"
                style={{
                  background: "#333",
                  color: "#fff",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  padding: "4px 8px",
                  fontSize: "12px",
                }}
              >
                {currentProduct[0].quantity - product.quantity || [
                  product.returned_qty,
                ]}{" "}
                qty added to return cart
              </p>
            )}

          <img
            className="product-image"
            src={product.image_url}
            alt={product.name}
          />
          <div style={{ padding: "15px" }}>
            <p
              style={{
                color: "#333",
                lineHeight: "1.5em",
                maxHeight: "3em",
                overflow: "hidden",
              }}
            >
              {product.product_title}
            </p>
            <p className="margin" style={{ color: "#333333B2" }}>
              {product.variant_name}
            </p>
            <p className="margin" style={{ color: "#333333B2" }}>
              Qty:{" "}
              {product.remaining_qty > 0
                ? product.remaining_qty
                : product.quantity}
            </p>
            <p
              className="margin"
              style={{ color: "#333333B2", fontWeight: "600" }}
            >
              {product.total_item_discount > 0 ? (
                <span>
                  ₹ {product.discounted_price}{" "}
                  <span
                    style={{
                      textDecoration: "line-through",
                      fontSize: "14px",
                      marginLeft: "4px",
                      fontWeight: "400",
                    }}
                  >
                    ₹{product.original_product_price}
                  </span>
                </span>
              ) : (
                `₹ ${product.product_price}`
              )}
            </p>
            <div className="radio-buttons">
              {!product.is_delivered || product.is_delivered == null ? (
                <p
                  style={{
                    color: "#333333B2",
                    fontSize: "14px",
                    padding: "1rem 0rem",
                  }}
                >
                  The item is yet to be delivered{" "}
                </p>
              ) : product.is_delivered && product.delivery_date_expired ? (
                <p
                  style={{
                    color: "#333333B2",
                    fontSize: "14px",
                    padding: "1rem 0rem",
                  }}
                >
                  Return/Exchange period is expired
                </p>
              ) : rmsConfig.restrict_multi_type_request_creation &&
                returnedProducts.length > 0 ? (
                <div>
                  {product.eligible &&
                    (product.refund_eligible || product.exchange_eligible) &&
                    product.remaining_qty > 0 && (
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="exchange-options"
                          name="exchange-group"
                        >
                          {rmsConfig.supports_exchange &&
                            !product.exchange_date_expired &&
                            returnedProducts.some(
                              (a) => a.return_type == "exchange"
                            ) && (
                              <FormControlLabel
                                id={product.line_item_id + "exchange"}
                                style={{ fontWeight: "500" }}
                                checked={
                                  webSelected &&
                                  product.line_item_id + "exchange" ===
                                    productid
                                }
                                disabled={
                                  !product.exchange_eligible ||
                                  !rmsConfig.supports_exchange
                                }
                                value="Exchange"
                                control={
                                  <Radio
                                    onChange={() => {
                                      dispatch(updateWebSelected(true));
                                      handleOnSelect(
                                        product.line_item_id,
                                        "exchange",
                                        product
                                      );
                                    }}
                                  />
                                }
                                label="Exchange"
                              />
                            )}
                          {rmsConfig.supports_refund &&
                            !product.return_date_expired &&
                            returnedProducts.some(
                              (a) => a.return_type == "refund"
                            ) && (
                              <FormControlLabel
                                id={product.line_item_id + "return"}
                                style={{ fontWeight: "500" }}
                                checked={
                                  webSelected &&
                                  product.line_item_id + "return" === productid
                                }
                                disabled={
                                  !product.refund_eligible ||
                                  !rmsConfig.supports_refund
                                }
                                value="Return"
                                control={
                                  <Radio
                                    onChange={() => {
                                      dispatch(updateWebSelected(true));
                                      handleOnSelect(
                                        product.line_item_id,
                                        "return",
                                        product
                                      );
                                    }}
                                  />
                                }
                                label="Return"
                              />
                            )}
                        </RadioGroup>
                      </FormControl>
                    )}
                </div>
              ) : product.eligible &&
                (product.refund_eligible || product.exchange_eligible) &&
                product.remaining_qty > 0 ? (
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="exchange-options"
                    name="exchange-group"
                  >
                    {rmsConfig.supports_exchange &&
                      !product.exchange_date_expired && (
                        <FormControlLabel
                          id={product.line_item_id + "exchange"}
                          style={{ fontWeight: "500" }}
                          checked={
                            webSelected &&
                            product.line_item_id + "exchange" === productid
                          }
                          disabled={
                            !product.exchange_eligible ||
                            !rmsConfig.supports_exchange
                          }
                          value="Exchange"
                          control={
                            <Radio
                              onChange={() => {
                                dispatch(updateWebSelected(true));
                                handleOnSelect(
                                  product.line_item_id,
                                  "exchange",
                                  product
                                );
                              }}
                            />
                          }
                          label="Exchange"
                        />
                      )}
                    {rmsConfig.supports_refund &&
                      !product.return_date_expired && (
                        <FormControlLabel
                          id={product.line_item_id + "return"}
                          style={{ fontWeight: "500" }}
                          checked={
                            webSelected &&
                            product.line_item_id + "return" === productid
                          }
                          disabled={
                            !product.refund_eligible ||
                            !rmsConfig.supports_refund
                          }
                          value="Return"
                          control={
                            <Radio
                              onChange={() => {
                                dispatch(updateWebSelected(true));
                                handleOnSelect(
                                  product.line_item_id,
                                  "return",
                                  product
                                );
                              }}
                            />
                          }
                          label="Return"
                        />
                      )}
                  </RadioGroup>
                </FormControl>
              ) : product.remaining_qty > 0 ? (
                <p
                  style={{
                    color: "#333333B2",
                    fontSize: "14px",
                    padding: "1rem 0rem",
                  }}
                >
                  {" "}
                  Return/Exchange is unavailable for sale products{" "}
                </p>
              ) : (
                <p
                  style={{
                    color: "#333333B2",
                    fontSize: "14px",
                    padding: "1rem 0rem",
                  }}
                >
                  {" "}
                  You've already placed return/exchange for this item. Please reach out to us by clicking on the Need Help button at the bottom of the page for further assistance.
                </p>
              )}
            </div>
          </div>
        </StyledProducts>
        <StyledMobButtons>
          {!product.is_delivered || product.is_delivered == null ? (
            <p
              style={{
                color: "#333333B2",
                fontSize: "14px",
                padding: "1rem 0rem 1rem 1rem",
              }}
            >
              The item is yet to be delivered{" "}
            </p>
          ) : product.is_delivered && product.delivery_date_expired ? (
            <p
              style={{
                color: "#333333B2",
                fontSize: "14px",
                padding: "1rem 0rem 1rem 1rem",
              }}
            >
              Return/Exchange period is expired
            </p>
          ) : rmsConfig.restrict_multi_type_request_creation &&
            returnedProducts.length > 0 ? (
            <div>
              {product.eligible &&
                (product.refund_eligible || product.exchange_eligible) &&
                product.remaining_qty > 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "20px 0px",
                    }}
                  >
                    {rmsConfig.supports_exchange &&
                      !product.exchange_date_expired &&
                      returnedProducts.some(
                        (a) => a.return_type == "exchange"
                      ) && (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            dispatch(mobSelected(true));
                            handleOnSelect(
                              product.line_item_id,
                              "exchange",
                              product
                            );
                          }}
                          disabled={
                            !product.exchange_eligible ||
                            !rmsConfig.supports_exchange
                          }
                          startIcon={<RepeatIcon />}
                          style={{
                            padding: "10px 25px",
                            background: "transparent",
                          }}
                        >
                          Exchange
                        </Button>
                      )}
                    {rmsConfig.supports_refund &&
                      !product.return_date_expired &&
                      returnedProducts.some(
                        (a) => a.return_type == "refund"
                      ) && (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            dispatch(mobSelected(true));
                            handleOnSelect(
                              product.line_item_id,
                              "return",
                              product
                            );
                          }}
                          disabled={
                            !product.refund_eligible ||
                            !rmsConfig.supports_refund
                          }
                          startIcon={<ReplayIcon />}
                          style={{
                            padding: "10px 25px",
                            background: "transparent",
                            marginLeft: "10px",
                          }}
                        >
                          Return
                        </Button>
                      )}
                  </div>
                )}
            </div>
          ) : product.eligible &&
            (product.refund_eligible || product.exchange_eligible) &&
            product.remaining_qty > 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "20px 0px",
              }}
            >
              {rmsConfig.supports_exchange &&
                !product.exchange_date_expired && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      dispatch(mobSelected(true));
                      handleOnSelect(product.line_item_id, "exchange", product);
                    }}
                    disabled={
                      !product.exchange_eligible || !rmsConfig.supports_exchange
                    }
                    startIcon={<RepeatIcon />}
                    style={{
                      padding: "10px 25px",
                      background: "transparent",
                    }}
                  >
                    Exchange
                  </Button>
                )}
              {rmsConfig.supports_refund && !product.return_date_expired && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    dispatch(mobSelected(true));
                    handleOnSelect(product.line_item_id, "return", product);
                  }}
                  disabled={
                    !product.refund_eligible || !rmsConfig.supports_refund
                  }
                  startIcon={<ReplayIcon />}
                  style={{
                    padding: "10px 25px",
                    background: "transparent",
                    marginLeft: "10px",
                  }}
                >
                  Return
                </Button>
              )}
            </div>
          ) : product.remaining_qty > 0 ? (
            <p
              style={{
                color: "#333333B2",
                fontSize: "14px",
                padding: "1rem 0rem 1rem 1rem",
              }}
            >
              {" "}
              Return/Exchange is unavailable for sale products{" "}
            </p>
          ) : (
            <p
              style={{
                color: "#333333B2",
                fontSize: "14px",
                padding: "1rem 0rem 1rem 1rem",
              }}
            >
              {" "}
              You've already placed return/exchange for this item. Please reach out to us by clicking on the Need Help button at the bottom of the page for further assistance.
            </p>
          )}
        </StyledMobButtons>
      </div>
    );
  };

  const spring = {
    type: "spring",
    stiffness: 50,
  };

  const StyledApp = styled.div`
    font-family: ${merchantConfig.google_font_family !== null
      ? merchantConfig.google_font_family
      : "Poppins,sans-serif"};
  `;

  useEffect(() => {
    let productset = [];
    let updatedOrder = { ...defaultSelectedOrder };
    if (returnedProducts !== undefined) {
      if (returnedProducts.length > 0) {
        productset = updatedOrder.order_items.filter(
          (a) =>
            !returnedProducts
              .map((a) => a.line_item_id)
              .includes(a.line_item_id)
        );
        let remainingProducts = updatedOrder.order_items.filter((a) =>
          returnedProducts.map((a) => a.line_item_id).includes(a.line_item_id)
        );
        for (let i = 0; i < returnedProducts.length; i++) {
          for (let j = 0; j < remainingProducts.length; j++) {
            if (
              returnedProducts[i].line_item_id ===
                remainingProducts[j].line_item_id &&
              returnedProducts[i].remaining_qty > 0
            ) {
              remainingProducts[j] = {
                ...remainingProducts[j],
                remaining_qty: returnedProducts[i].remaining_qty,
              };
              productset.push(remainingProducts[j]);
            }
          }
        }
        updatedOrder.order_items = productset;
      }
      setSelectedOrder(updatedOrder);
    }
  }, [returnedProducts]);

  return (
    <StyledApp>
      {location.pathname === "/products" && (
        <Header
          ordernum={
            selectedOrder &&
            selectedOrder !== null &&
            selectedOrder.order_number
          }
        />
      )}
      {rmsConfig.product_tag_consent && location.pathname === "/products" && (
        <p
          style={{
            color: merchantConfig.secondary_theme_color,
            background: merchantConfig.primary_theme_color,
            width: "100%",
            textAlign: "center",
            position: "fixed",
            top: "80px",
            height: "70px",
            padding: "20px 0px",
            zIndex: "25",
          }}
        >
          {rmsConfig.product_tag_consent_text}
        </p>
      )}
      {apiErrorLoading ? (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "80vh",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <ErrorIcon />
          <p
            style={{
              marginTop: "25px",
              color: "#1d8dcc",
              textDecoration: "underline",
              cursor: "pointer",
              fontSize: "20px",
            }}
            onClick={() => window.location.reload()}
          >
            Reload
          </p>
        </div>
      ) : (
        <StyledProductsWrapper
          location={location.pathname}
          returnedProducts={returnedProducts}
          productConsent={rmsConfig.product_tag_consent}
        >
          {productsLoading ? (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {[...Array(4).keys()].map((a) => {
                return (
                  <div className="products-skeleton" key={a}>
                    <Skeleton
                      variant="rectangular"
                      className="image-skeleton"
                    />
                    <div style={{ marginLeft: "20px", marginTop: "10px" }}>
                      <Typography className="text-skeleton" variant="h4">
                        {" "}
                        <Skeleton variant="text" />
                      </Typography>
                      <Typography className="text-skeleton" variant="h4">
                        {" "}
                        <Skeleton variant="text" />
                      </Typography>
                      <Typography className="text-skeleton" variant="h4">
                        {" "}
                        <Skeleton variant="text" />
                      </Typography>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <Fragment>
              {location.pathname === "/products" && (
                <p className="select">Select products for return or exchange</p>
              )}
              <Grid
                alignItems="center"
                container
                rowSpacing={{ xs: 2, md: 4 }}
                gridTemplateColumns="repeat(12, 1fr)"
                columnSpacing={4}
              >
                {selectedOrder &&
                  selectedOrder.order_items &&
                  selectedOrder.order_items
                    .filter((a) => a.eligible)
                    .map((product, i) => {
                      return (
                        <Grid
                          key={i}
                          justifyContent="center"
                          item
                          xs={12}
                          md={
                            (location.pathname === "/products" &&
                              webSelected) ||
                            location.pathname === "/return-cart"
                              ? 12
                              : location.pathname === "/products" &&
                                !webSelected
                              ? 6
                              : 12
                          }
                        >
                          <motion.div layout transition={spring}>
                            <ProductCard
                              product={product}
                              key={product.line_item_id}
                              webSelected={webSelected}
                            />
                          </motion.div>
                        </Grid>
                      );
                    })}

                {selectedOrder &&
                  selectedOrder.order_items &&
                  selectedOrder.order_items
                    .filter((a) => !a.eligible)
                    .map((product, i) => {
                      return (
                        <Grid
                          key={i}
                          justifyContent="center"
                          item
                          xs={12}
                          md={
                            (location.pathname === "/products" &&
                              webSelected) ||
                            location.pathname === "/return-cart"
                              ? 12
                              : location.pathname === "/products" &&
                                !webSelected
                              ? 6
                              : 12
                          }
                        >
                          <motion.div layout transition={spring}>
                            <ProductCard
                              product={product}
                              key={product.line_item_id}
                              webSelected={webSelected}
                            />
                          </motion.div>
                        </Grid>
                      );
                    })}
              </Grid>
              {webSelected && (
                <div
                  style={{
                    margin: "auto",
                    position: "fixed",
                    width: "50%",
                    right: "0",
                    top:
                      location.pathname === "/return-cart" ? "220px" : "160px",
                    zIndex: "99",
                  }}
                >
                  <Reason handleOnSelect={handleOnSelect} />
                </div>
              )}
              {selected && (
                <Drawer
                  style={{ maxHeight: "90vh" }}
                  anchor="bottom"
                  open={selected}
                  onClose={() => dispatch(mobSelected(true))}
                >
                  <Reason handleOnSelect={handleOnSelect} />
                </Drawer>
              )}
              {location.pathname === "/products" &&
                merchantConfig.whatsapp_help &&
                merchantConfig.whatsapp_help !== null && (
                  <a
                    href={merchantConfig.whatsapp_help}
                    target="_blank"
                    rel="noreferrer"
                    className="help"
                    style={{
                      fontWeight: "600",
                      fontSize: "12px",
                      color: "var(--color-primary)",
                      margin: "60px 0px",
                      textDecoration: "underline",
                      display: "block",
                    }}
                  >
                    Need Help?
                  </a>
                )}
            </Fragment>
          )}
        </StyledProductsWrapper>
      )}
    </StyledApp>
  );
}

export default ProductsDisplay;
