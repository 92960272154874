import styled from "styled-components";

export const StyledFooterWrapper = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
    text-align: center;
    padding: 5px 0px;
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.12);
    font-size: 12px;
    z-index: 7;
    display: flex;
    justify-content: center;

    div{
        width: 90px;
        margin: 0px;
        padding-left: 10px;
        margin-top: 2px;
    }

    @media screen and (min-width: 768px){
        justify-content: flex-end;
        background: transparent;
        right: 20px;
        bottom: 10px;        
        box-shadow: none;
        width: max-content;

        div{
            margin: 1px 0px;
        }
    }
`