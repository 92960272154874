import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getSameVariants } from "utils/api";
import { useDispatch, useSelector } from "react-redux";
import { getMerchantConfig } from "features/config/configSlice";
import {
  addNewProduct,
  addProceedBool,
  addProductType,
  getSelectedProduct,
  getSelectedReason,
  getSelectedSubReason,
  mobSelected,
  returnQuantity,
  returnType,
} from "features/product/productSlice";
import {
  addReturnedProducts,
  getReturnedProducts,
} from "features/returnedProducts/returnedProductSlice";
import {
  Button,
  MenuItem,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { updateWebSelected } from "features/orders/orderSlice";
import { ReactComponent as ErrorIcon } from "assets/error.svg";
// import { ReactComponent as FrownIcon } from "assets/frown.svg";
import { StyledDifferentProduct, StyledVariantSelect } from "./styled";
import { ReactComponent as Search } from "assets/search.svg";

function SameProduct({
  quantityInc,
  productType,
  selectedVariant,
  setSelectedVariant,
  otherReason,
}) {
  const merchantConfig = useSelector(getMerchantConfig);
  const [sameVariantProducts, setSameVariantProducts] = useState({});
  const dispatch = useDispatch();
  const selectedProduct = useSelector(getSelectedProduct);
  const returnedProducts = useSelector(getReturnedProducts);
  const [sameVariantLoader, setSameVariantLoader] = useState(true);
  const reason = useSelector(getSelectedReason);
  const subReason = useSelector(getSelectedSubReason);
  const [sameApiError, setSameApiError] = useState(false);
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const encipherencode =
    sessionStorage.getItem("encipherencode") || params.get("encipherencode");

  useEffect(() => {
    getSameVariants(
      merchantConfig.merchant_id,
      selectedProduct.product_id,
      quantityInc,
      selectedProduct.product_price,
      selectedProduct.variant_id,
      selectedProduct.sku
    )
      .then((resp) => {
        setSameVariantProducts(resp.data);
        setSameVariantLoader(false);
      })
      .catch(() => {
        setSameApiError(true);
      });
  }, []);

  const handleProductExchange = () => {
    dispatch(mobSelected(false));
    dispatch(updateWebSelected(false));
    dispatch(addNewProduct(selectedVariant));
    dispatch(returnQuantity(quantityInc));
    dispatch(returnType("exchange"));
    let tempProducts = [...returnedProducts];
    let line_item_ids = tempProducts.map((a) => a.line_item_id);
    if (line_item_ids.indexOf(selectedProduct.line_item_id) !== -1) {
      let index = line_item_ids.indexOf(selectedProduct.line_item_id);
      tempProducts.splice(index, 1);
    }
    dispatch(
      addReturnedProducts([
        ...tempProducts,
        {
          merchant_id: merchantConfig.merchant_id,
          product_title: selectedProduct.product_title,
          variant_name: selectedProduct.variant_name,
          product_price: selectedProduct.product_price,
          variant_image_url: selectedProduct.image_url,
          variant_sku: selectedProduct.sku,
          product_id: selectedProduct.product_id,
          variant_id: selectedProduct.variant_id,
          quantity: quantityInc,
          remaining_qty: selectedProduct.quantity - quantityInc,
          total_item_discount: selectedProduct.total_item_discount,
          discounted_price: selectedProduct.discounted_price,
          original_product_price: selectedProduct.original_product_price,
          exchange_product_title: sameVariantProducts.title,
          exchange_variant_name: selectedVariant.title,
          exchange_product_sku: selectedVariant.sku,
          exchange_product_price: selectedVariant.price,
          exchange_variant_image_url:
            selectedVariant.variant_img || selectedVariant.src,
          exchange_product_id: selectedProduct.product_id,
          exchange_variant_id: selectedVariant.variant_id,
          exchange_type: "same",
          return_type: "exchange",
          customer_reason_code: reason.reason_code,
          customer_reason_label: reason.reason_label,
          customer_reason_comment: otherReason,
          customer_sub_reason_code:
            subReason && subReason !== null ? subReason.reason_code : null,
          customer_sub_reason_label:
            subReason && subReason !== null ? subReason.reason_label : null,
          line_item_id: selectedProduct.line_item_id,
        },
      ])
    );
    let url = "/return-cart";
    if (encipherencode !== null && encipherencode !== undefined) {
      url += `?encipherencode=${encipherencode}`;
    }
    navigate(url);
  };

  useEffect(() => {
    if (
      sameVariantProducts &&
      sameVariantProducts !== null &&
      Object.keys(sameVariantProducts).length > 0 &&
      sameVariantProducts.variants.length > 0
    ) {
      if (
        sameVariantProducts.variants.filter(
          (a) => a.variant_id != selectedProduct.variant_id
        ).length > 0
      ) {
        setSelectedVariant(
          sameVariantProducts.variants.filter(
            (a) => a.variant_id != selectedProduct.variant_id
          )[0]
        );
      } else {
        setSelectedVariant(sameVariantProducts.variants[0]);
      }
    } else {
      setSelectedVariant(null);
    }
  }, [sameVariantProducts]);

  const handleVariant = (event) => {
    setSelectedVariant(event.target.value);
  };

  return (
    <div>
      {productType !== null && (
        <p
          style={{
            color: "#333333",
            fontSize: "16px",
            margin: "10px 0px",
            fontWeight: "500",
            padding: "10px 20px 0px",
          }}
        >
          Exchanging with{" "}
          {productType === "same" ? " variant of same " : " different "}{" "}
          product.{" "}
          <span
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              color: "#1D8DCC",
            }}
            onClick={() => {
              dispatch(addProceedBool(false));
              dispatch(addProductType(null));
            }}
          >
            {" "}
            Change
          </span>
        </p>
      )}
      {/* <p style={{ color: "#333333B2", fontSize: "14px", padding: "0px 20px" }}>
        Select an exchange product for{" "}
        <span
          style={{
            textDecoration: "underline",
            fontWeight: "600",
            color: "#333",
          }}
        >
          {quantityInc} unit{quantityInc > 1 ? "s" : ""}
        </span>
      </p> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {sameApiError ? (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
              flexDirection: "column",
              marginTop: "100px",
              padding: "0px 20px",
            }}
          >
            <ErrorIcon />
            <p
              style={{
                marginTop: "25px",
                color: "#1d8dcc",
                textDecoration: "underline",
                cursor: "pointer",
                fontSize: "20px",
              }}
              onClick={() => window.location.reload()}
            >
              Reload
            </p>
          </div>
        ) : sameVariantLoader ? (
          [...Array(3).keys()].map((a) => {
            return (
              <div key={a} style={{ padding: "0px 20px" }}>
                <Skeleton
                  style={{
                    width: "140px",
                    height: "150px",
                    margin: "0px 5px 6px",
                  }}
                />
                <Typography
                  style={{
                    color: "#333333B2",
                    margin: "5px 0px",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  {" "}
                  <Skeleton />{" "}
                </Typography>
                <Typography
                  style={{
                    color: "#333333B2",
                    margin: "5px 0px",
                    fontSize: "14px",
                  }}
                >
                  {" "}
                  <Skeleton />
                </Typography>
                <Typography
                  style={{
                    color: "#333",
                    fontSize: "16px",
                    margin: "5px 0px",
                    fontWeight: "600",
                  }}
                >
                  {" "}
                  <Skeleton />
                </Typography>
              </div>
            );
          })
        ) : sameVariantProducts.variants.length > 0 &&
          selectedVariant !== null ? (
          <div style={{ margin: "10px 0px", width: "100%" }}>
            <StyledDifferentProduct>
              <img
                src={selectedVariant.variant_img || selectedVariant.src}
                alt="Preview"
                className="product-image"
              />
              <div className="product-details">
                <p className="product-title">{sameVariantProducts.title}</p>
                <p
                  style={{
                    color: "#333333B2",
                    fontSize: "12px",
                    margin: "10px 0px",
                  }}
                >
                  {selectedVariant.title.indexOf("Default") === -1
                    ? selectedVariant.title
                    : sameVariantProducts.title}
                </p>
                {/* <p
                  style={{
                    color: "#333",
                    fontSize: "16px",
                    margin: "5px 0px",
                    fontWeight: "600",
                  }}
                >
                  ₹ {selectedVariant.price}
                </p> */}
                {selectedVariant &&
                  selectedVariant !== null &&
                  (selectedVariant.out_of_stock ||
                    !selectedVariant.quantity_available_for_exchange) && (
                    <p
                      className="out-of-stock"
                      style={{
                        color: "red",
                      }}
                    >
                      Out of stock
                    </p>
                  )}
                {sameVariantProducts.variants.length >= 10 && (
                  <TextField
                    style={{ width: "fit-content", margin: "15px 0px" }}
                    select
                    variant="outlined"
                    value={selectedVariant}
                    onChange={handleVariant}
                  >
                    {sameVariantProducts.variants
                      .filter((a) => {
                        return !reason.same_variant_exchange
                          ? a.variant_id != selectedProduct.variant_id
                          : true;
                      })
                      .map((variant) => {
                        return (
                          <MenuItem value={variant} key={variant.id}>
                            {variant.title}
                          </MenuItem>
                        );
                      })}
                  </TextField>
                )}
              </div>
            </StyledDifferentProduct>
            {sameVariantProducts.variants.length < 10 &&
              sameVariantProducts.variants.length > 1 && (
                <StyledVariantSelect>
                  <p
                    style={{
                      margin: "10px 0px",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    Select Variant
                  </p>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {sameVariantProducts.variants
                      .filter((a) => {
                        return !reason.same_variant_exchange
                          ? a.variant_id != selectedProduct.variant_id
                          : true;
                      })
                      .map((variant) => {
                        return (
                          <Button
                            disableRipple
                            variant={
                              selectedVariant !== null &&
                              selectedVariant.variant_id === variant.variant_id
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() => setSelectedVariant(variant)}
                            style={{
                              fontSize: "12px",
                              minWidth: "50px",
                              width: "fit-content",
                              margin: "8px",
                              marginLeft: "0px",
                              background:
                                selectedVariant !== null &&
                                selectedVariant.variant_id ===
                                  variant.variant_id
                                  ? `${merchantConfig.primary_theme_color}1A`
                                  : "transparent",
                              color:
                                selectedVariant !== null &&
                                selectedVariant.variant_id ===
                                  variant.variant_id
                                  ? `#000`
                                  : `${merchantConfig.primary_theme_color}`,
                            }}
                            key={variant.id}
                          >
                            {variant.title}
                          </Button>
                        );
                      })}
                  </div>
                </StyledVariantSelect>
              )}

            <div className="diff-prod-buttons" style={{ display: "block" }}>
              <Button
                className="exchange-button-web"
                color="primary"
                onClick={() => handleProductExchange()}
                variant="contained"
                disabled={
                  selectedVariant &&
                  selectedVariant !== null &&
                  (selectedVariant.out_of_stock ||
                    !selectedVariant.quantity_available_for_exchange)
                }
              >
                Select for exchange
              </Button>
              <Button
                variant="text"
                color="primary"
                className="back-button"
                onClick={() => {
                  dispatch(addProceedBool(false));
                  dispatch(addProductType(null));
                }}
              >
                Back
              </Button>
              <div className="exchange-button-mob">
                <Button
                  color="primary"
                  fullWidth
                  onClick={() => handleProductExchange()}
                  variant="contained"
                  style={{
                    transform: "translateX(-50%)",
                    left: "50%",
                    padding: "14px 0px",
                  }}
                  disabled={
                    selectedVariant &&
                    selectedVariant !== null &&
                    (selectedVariant.out_of_stock ||
                      !selectedVariant.quantity_available_for_exchange)
                  }
                >
                  Select for exchange
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              justifyContent: "center",
              textAlign: "center",
              marginTop: "4rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            {/* <FrownIcon /> */}
            <Search />
            <p style={{ textAlign: "center", marginTop: "1.5rem" }}>
              {/* Sorry we couldn't find products of same variant */}
              Hmm we don't have that..yet
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default SameProduct;
